import * as utils from '../utils.js';

class Footer {

  constructor(el) {

    console.log('initFooter');

	  this.DOM = {};
	  this.DOM.el = el;
	  this.DOM.phone = this.DOM.el.querySelector('.socials > a');
	  this.DOM.socials = this.DOM.el.querySelector('.socials');
    this.DOM.title = this.DOM.el.querySelector('.block__title');
    this.DOM.titleChars = new SplitText(this.DOM.title, {type:"chars"});
    this.DOM.question = this.DOM.el.querySelector('.contact > p');
    this.DOM.questionLines = new SplitText(this.DOM.question, {type:"lines"});
    this.DOM.mentions = this.DOM.el.querySelector('.mentions');
    this.DOM.mentionsLines = new SplitText(this.DOM.mentions, {type:"lines"});

	  this.init();

  }

  init() {

    const TLFooter = new TimelineMax({ paused:true })
    TLFooter.from(this.DOM.title, 1, {top:-20, ease:Power3.easeOut}, 0);
    TLFooter.staggerFrom(this.DOM.titleChars.chars, 1, {x:20, opacity:0, ease:Power3.easeOut}, 0.05, 0);
    TLFooter.staggerFrom(this.DOM.questionLines.lines, 1, {y:80, opacity:0, ease:Power3.easeOut}, 0.05, 0);
    TLFooter.from(this.DOM.phone, 1, {y:60, opacity:0, ease:Power3.easeOut}, 0.20);
    TLFooter.staggerFrom(this.DOM.socials.querySelectorAll('li'), 1, {y:60, opacity:0, ease:Power3.easeOut}, 0.05, 0.25);
    TLFooter.staggerFrom(this.DOM.mentionsLines.lines, 1, {y:60, opacity:0, ease:Power3.easeOut}, 0.05, 0.3);

    inView('footer > .container').on('enter', el => {
      TLFooter.duration(1.8).play();
    }).on('exit', el => {
      TLFooter.duration(0.2).reverse();
    });

  }

}

export default Footer;
