export function initCase() {

	var Case = Barba.BaseView.extend({

		namespace: 'single-case',

		onEnter: function() {

			console.log('Init Case');

			// Toggle Color;
			const caseColor = this.container.dataset.color;
			document.querySelector('.toggle__menu .round').style.backgroundColor = caseColor;

			// Setup Slider;
			const Sliders = document.querySelectorAll('.case__slider .swiper-container');
			Sliders.forEach((elem) => {

				let swiperCase = new Swiper(elem, {
				    speed: 800,
						loop:true,
						navigation: {
			        nextEl: elem.parentNode.querySelector('.swiper-button-next'),
			        prevEl: elem.parentNode.querySelector('.swiper-button-prev'),
			      },
				});

			});

		},

		onLeave: function() {
			//TweenMax.ticker.removeEventListener("tick", pageHomeRender, this, true, 1);
			document.querySelector('.toggle__menu .round').style.backgroundColor = "#eb4c49";
		}

	})

	Case.init();

}
