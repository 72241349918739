export function initClients() {

	//var pageHomeRender;
	var Clients = Barba.BaseView.extend({

		namespace: 'page-clients',

		onEnter: function() {

			console.log('Init Clients');
			const container = this.container;

			//Visuels Slider
			const swiperCases = new Swiper('.bandeau__visuels .swiper-container', {
			    speed: 8000,
			    spaceBetween: 0,
					slidesPerView: 5,
					loop:true,
					simulateTouch:false,
					shortSwipes:false,
					longSwipes:false,
					touchRatio:0,
					autoplay:{
						delay:0,
						disableOnInteraction:false,
					},
					breakpoints: {
						768: {
							slidesPerView: 2
						},
						1200: {
							slidesPerView: 3
						},
						1440: {
							slidesPerView: 4
						}
					}
			});

		},

		onLeaveCompleted: function() {
			//TweenMax.ticker.removeEventListener("tick", pageHomeRender, this, true, 1);
		}

	})

	Clients.init();

}
