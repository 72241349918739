export function initHome() {

	//var pageHomeRender;
	var Home = Barba.BaseView.extend({

		namespace: 'page-home',

		onEnter: function() {

			console.log('Init Home');
			const container = this.container;

			//Intro Words
			const wordsContainer = container.querySelector('h1 .words');
			const wordsLetters = new SplitText(wordsContainer.querySelectorAll('span'), {type:"chars"});
			TweenMax.set(wordsContainer.querySelectorAll('span'),  {y:"150%"});
			let currentWord = wordsContainer.querySelector('.active');
			TweenMax.set(currentWord,  {y:"0%"});
			TweenMax.set(currentWord.querySelectorAll('div'), {y:"0%"});

			let maxWidth = 0;
			wordsContainer.querySelectorAll('span').forEach((word) => {
				if (maxWidth < word.offsetWidth) maxWidth = word.offsetWidth;
			})
			TweenMax.set(wordsContainer,  {width:maxWidth + 20});


			window.addEventListener('resize', function(e) {

				let maxWidth = 0;
				wordsContainer.querySelectorAll('span').forEach((word) => {
					if (maxWidth < word.offsetWidth) maxWidth = word.offsetWidth;
				})
				TweenMax.set(wordsContainer,  {width:maxWidth + 20});

			});

			setInterval( () => {

				currentWord = wordsContainer.querySelector('.active');
				let nextItem = currentWord.nextSibling;
				if (!nextItem) nextItem = wordsContainer.firstChild;

				currentWord.classList.remove('active');
				nextItem.classList.add('active');

				TweenMax.to(currentWord, 0.8,  {y:"-150%", ease:Power3.easeInOut}, 0);
				TweenMax.staggerTo(currentWord.querySelectorAll('div'), 0.8,  {y:"-100%", ease:Power3.easeInOut}, 0.02, 0);
				TweenMax.set(nextItem,  {y:"150%"});
				TweenMax.set(nextItem.querySelectorAll('div'), {y:"100%"});
				TweenMax.to(nextItem, 0.8,  {y:"0%", ease:Power3.easeInOut}, 0);
				TweenMax.staggerTo(nextItem.querySelectorAll('div'), 0.8,  {y:"0%", ease:Power3.easeInOut}, 0.02, 0);


			}, 3000);


			//About Slider
			const swiperAbout = new Swiper('.about .swiper-container', {
			    speed: 0,
					loop:true,
					autoplay:{
						delay:4000
					},
					virtualTranslate:true,
					navigation: {
		        nextEl: '.about .swiper-button-next',
		        prevEl: '.about .swiper-button-prev',
		      },
					on : {
						init : function () {
							this.zindexPrevSlide = 100;
						},
						slideChangeTransitionStart : function () {
							let prevSlide = this.slides[this.activeIndex];
							this.zindexPrevSlide++
							prevSlide.style.zIndex = this.zindexPrevSlide;
						}
					}
			});

			//Cases Slider
			const swiperCases = new Swiper('.cases .swiper-container', {
			    speed: 800,
			    spaceBetween: 70,
					slidesPerView: 3,
					watchSlidesVisibility:true,
					navigation: {
		        nextEl: '.cases .swiper-button-next',
		        prevEl: '.cases .swiper-button-prev',
		      },
					breakpoints: {
						768: {
							spaceBetween: 20,
							slidesPerView: 1
						},
						1200: {
							spaceBetween: 30,
							slidesPerView: 2
						},
						1440: {
							spaceBetween: 30,
							slidesPerView: 3
						}
					}
			});

		},

		onLeaveCompleted: function() {
			//TweenMax.ticker.removeEventListener("tick", pageHomeRender, this, true, 1);
		}

	})

	Home.init();

}
