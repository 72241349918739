export function init404() {

	var Page = Barba.BaseView.extend({

		namespace: 'page-404',

		onEnter: function() {

			console.log('Init 404');
			const logo = document.querySelector('header .brand');

			setTimeout(() => {

				logo.click();

			}, 4000);

		},

		onLeave: function() {

		}

	})

	Page.init();

}
