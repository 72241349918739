import 'nodelist-foreach-polyfill'
import FastClick from 'fastclick';
import * as utils from './utils.js';
import Header from './modules/header.js';
import Footer from './modules/footer.js';
import {initHome} from './pages/home.js';
import {initJobs} from './pages/jobs.js';
import {initCase} from './pages/case.js';
import {initClients} from './pages/clients.js';
import {initServices} from './pages/services.js';
import {init404} from './pages/404.js';

let sy = 0;
const devMode = false;
const body = document.body;
const footer = document.querySelector('footer');
const mainLoader = document.querySelector('body > .loader__container');
const barbaWrapper = document.querySelector('#barba-wrapper');
const animationDuration = 1.6;

class App {

  constructor(opt = {}) {

    window.scrollTo(0,0)
    console.log("%cCreated by Wokine, with ❤","color: #000; padding: 5px 0px 1px; border-bottom:2px solid #71d1c2;"),console.log("%chttp://wokine.com","color:#ccc")

    if (devMode) {
  		console.log('DEVMODE BIATCH');
      console.log(window.Sniff);
    }

    this.init()
    this.addEvents()
    this.intro();

  }

  init() {

	  FastClick(document.body);
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    history.scrollRestoration = 'manual';

    //header / footer
  	const header = new Header(document.querySelector('header'));
    const footer = new Footer(document.querySelector('footer'));

    Barba.Dispatcher.on('newPageReady', (currentStatus, oldStatus, container, newPageRawHTML) => {

      //newPageReady
      const viewMores = container.querySelectorAll('.view__more');
      if (viewMores) {
        viewMores.forEach(function(lien) {
          lien.titleChars = new SplitText(lien, {type:"chars"});
        });
      }

      //update lang
      var parser = new DOMParser();
      const newDoc = parser.parseFromString(newPageRawHTML, "text/html");
      document.querySelector('header .lang').innerHTML = newDoc.querySelector('header .lang').innerHTML;


      //videos
      const videosVisuels = container.querySelectorAll('.video__visuel.width--sound');
      if (videosVisuels) {
        videosVisuels.forEach(function(video) {
          let videoMute = video.querySelector('.video__sound');
          let videoVid = video.querySelector('video');
          videoVid.volume = 0;
          videoVid.muted = false;
          videoMute.addEventListener('click', (e)=>{
            e.preventDefault();
            videoMute.classList.toggle('muted');
            if (videoMute.classList.contains('muted')) TweenMax.to(videoVid, 1.2, {volume:0, ease:Power3.easeOut});
            else TweenMax.to(videoVid, 1.2, {volume:0.5, ease:Power3.easeIn});
          });
        })
      }

      //Sliders
			const Sliders = document.querySelectorAll('.slider__visuels .swiper-container');
			Sliders.forEach((elem) => {

				let swiperVisuels = new Swiper(elem, {
				    speed: 0,
            virtualTranslate:true,
						loop:true,
						preloadImages: false,
						lazy: {
              loadPrevNext:true
            },
						navigation: {
			        nextEl: elem.parentNode.parentNode.querySelector('.swiper-button-next'),
			        prevEl: elem.parentNode.parentNode.querySelector('.swiper-button-prev'),
			      },
						on : {
							init : function () {
								this.zindexPrevSlide = 100;
							},
							slideChangeTransitionStart : function () {
								let prevSlide = this.slides[this.activeIndex];
								this.zindexPrevSlide++
								prevSlide.style.zIndex = this.zindexPrevSlide;
							}
						}
				});

			});


      if (!window.Sniff.features.mobile) {
        this.initPrllx(container);
        this.initInView(container);
      }


    })

    Barba.Dispatcher.on('linkClicked', (HTMLElement, MouseEvent) => {

      //linkClicked
      /*console.log(HTMLElement.dataset.color);
      if (HTMLElement.dataset.color) {
        mainLoader.style.backgroundColor = HTMLElement.dataset.color;
      } else {
        mainLoader.style.backgroundColor = '#eb4c49';
      }*/

    })

    Barba.Dispatcher.on('initStateChange', function() {

      //initStateChange
  		if (typeof ga === 'function') {
  			ga('send', 'pageview', location.pathname);
  		}

  	});

    var pageTransition = Barba.BaseTransition.extend({

  		start: function() {

  		  Promise.all([this.newContainerLoading, this.transitionOut()])
  		  .then(this.transitionIn.bind(this));

  		},

  		transitionOut: function() {

  			let deferred = Barba.Utils.deferred();
  			let oldContainer = this.oldContainer;

			  const pageOut = new TimelineMax({ paused:true, onComplete:()=>{

          if(header.menuOpen){
            var click = new Event('click');
            header.DOM.toggle.dispatchEvent(click);
          }

          window.scrollTo(0,0);
				  deferred.resolve();

			  }});

        mainLoader.style.visibility = 'visible';
        pageOut.fromTo(mainLoader, 0.8, {y:"100%"}, {y:"0%", ease:Power3.easeOut}, 0);
        pageOut.fromTo(mainLoader.querySelector('.brand'), 0.8, {y: -window.innerHeight}, {y:0, ease:Power3.easeOut}, 0);
        pageOut.play();

  			return deferred.promise;

  		},

  		transitionIn: function() {

  			var _this = this;

  			this.oldContainer.style.display = "none";
  			this.newContainer.style.visibility = 'visible';

        let h1Split;
        if (!this.newContainer.classList.contains('page--home')) { h1Split = new SplitText(this.newContainer.querySelector('h1'), {type:"words, chars"}); }

		    const pageIn = new TimelineMax({ paused:true, onComplete:()=>{
          mainLoader.style.visibility = 'hidden';
          if (!this.newContainer.classList.contains('page--home')) { document.querySelector('h1').innerHTML = h1Split._originals; }
					_this.done();
				}})

        pageIn.to(mainLoader, 0.8, {y:"-100%", ease:Power3.easeOut}, 1);
        pageIn.to(mainLoader.querySelector('.brand'), 0.8, {y:window.innerHeight, ease:Power3.easeOut}, 1);
        if (!this.newContainer.classList.contains('page--home')) { pageIn.staggerFrom(h1Split.chars, 0.8, {y:"80%", ease:Power3.easeOut}, 0.010, 0.8); }
        pageIn.from(this.newContainer, 0.8, {y:200, ease:Power3.easeOut}, 1);
        pageIn.play();

  		}

  	});

  	Barba.Pjax.getTransition = function() {
  	  return pageTransition;
  	};

  	//routes
  	initHome();
    initJobs();
    initCase();
    initClients();
    initServices();
    init404();

    Barba.Pjax.start();


  }

  addEvents() {

    window.addEventListener('scroll', (e) => {
  		sy = window.pageYOffset || document.documentElement.scrollTop;
  	})

  }

  initPrllx(container) {

	  const TLPrllxs = []
	  const prllxs = container.querySelectorAll('*[data-prllx]');
	  prllxs.forEach((item) => {

		    let prllxTL = new TimelineMax({ paused: true});
        TweenMax.set(item, { y: -1 * item.getAttribute('data-prllx') });
  	    prllxTL.to(item, 1, { y:item.getAttribute('data-prllx'), overwrite:"all", ease:Power0.easeNone });
		    TLPrllxs.push(prllxTL);

	  });

	  var prllxRender = function (){

	    prllxs.forEach((item, index) => {
        let from = item.getBoundingClientRect().top + sy - window.innerHeight ;
	      let norm = utils.clamp(utils.normalize(sy,  from ,  item.getBoundingClientRect().top + sy + item.offsetHeight), 0, 1);
		    TLPrllxs[index].progress(norm);
	    });

	  }

	  TweenMax.ticker.addEventListener("tick", prllxRender, this, true, 1);

  }

  initInView(container) {

    //Titles
    const titleInElems = container.querySelectorAll('.block__title');
    if (titleInElems) {
      titleInElems.forEach((elem) => {
        elem.titleChars = new SplitText(elem, {type:"chars"});
        elem.titleInTL = new TimelineMax({ paused: true});
        elem.titleInTL.from(elem, 1, {top:-40, ease:Power3.easeOut}, 0);
        elem.titleInTL.staggerFrom(elem.titleChars.chars, 1, {x:20, opacity:0, ease:Power3.easeOut}, 0.05, 0);
      })

      inView("main .block__title").on('enter', el => {
        if(!el.done) el.titleInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.titleChars._originals;
        //el.titleInTL.duration(0.2).reverse();
      });
    }

    //SubTitles
    const subtitleInElems = container.querySelectorAll('.block__subtitle');
    if (subtitleInElems) {
      subtitleInElems.forEach((elem) => {
        elem.subtitleChars = new SplitText(elem, {type:"lines, words, chars"});
        elem.subtitleInTL = new TimelineMax({ paused: true, onComplete:function(){

        }});
        elem.subtitleInTL.from(elem, 1, {y:80, opacity:0, ease:Power3.easeOut}, 0.05, 0);
        elem.subtitleInTL.staggerFrom(elem.subtitleChars.chars, 1, {y:"100%", ease:Power3.easeOut}, 0.02, 0);
      });

      inView("main .block__subtitle").on('enter', el => {
        if(!el.done)  el.subtitleInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.subtitleChars._originals;
        //el.subtitleInTL.duration(0.2).reverse();
      });
    }


    //fadeIn
    const fadeInElems = container.querySelectorAll('.fadeInView');
    if (fadeInElems) {
      fadeInElems.forEach((elem) => {
        elem.fadeInTL = new TimelineMax({ paused: true});
        elem.fadeInTL.from(elem, 1, {y:80, opacity:0, ease:Power3.easeOut}, 0);
      })
      inView("main .fadeInView").on('enter', el => {
        if(!el.done)  el.fadeInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        //el.fadeInTL.duration(0.2).reverse();
      });
    }

    //letterIn
    const lettersInElems = container.querySelectorAll('.lettersInView');
    if (lettersInElems) {

      lettersInElems.forEach((elem) => {
        elem.splitLetters = new SplitText(elem, {type:"chars"});
        elem.lettersInTL = new TimelineMax({ paused: true});
        elem.lettersInTL.staggerFrom(elem.splitLetters.chars, 1, {y:20, opacity:0, ease:Power3.easeOut}, 0.015, 0);
      })

      inView("main .lettersInView").on('enter', el => {
        if(!el.done)  el.lettersInTL.duration(animationDuration).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.splitLetters._originals;
        //el.lettersInTL.duration(0.2).reverse();
      });

    }

    //linesIn
    const linesInElems = container.querySelectorAll('.linesInView');
    if (linesInElems) {

      linesInElems.forEach((elem) => {
        elem.splitLines = new SplitText(elem, {type:"lines"});
        elem.linesInTL = new TimelineMax({ paused: true});
        elem.linesInTL.staggerFrom(elem.splitLines.lines, 1, {y:20, opacity:0, ease:Power3.easeOut}, 0.05, 0.2);
      })

      inView("main .linesInView").on('enter', el => {
        if(!el.done)  el.linesInTL.duration(animationDuration * 1.5).play();
      }).on('exit', el => {
        el.done = true;
        el.innerHTML = el.splitLines._originals;
        //el.linesInTL.duration(0.2).reverse();
      });

    }


    //CasePreviews
    const casesPreviewElems = container.querySelectorAll('.case__preview');
    if (casesPreviewElems) {

      casesPreviewElems.forEach((elem) => {
        elem.casePreviewTL = new TimelineMax({ paused: true});
        elem.casePreviewTL.from(elem, 1, {y:60, ease:Power3.easeOut}, 0);
        elem.casePreviewTL.from(elem.querySelector('.view__details'), 1, {y:20, opacity:0, ease:Power3.easeOut}, 0);
      })

      inView("main .case__preview").on('enter', el => {
        el.casePreviewTL.duration(animationDuration * 0.5).play();
      }).on('exit', el => {
        el.casePreviewTL.duration(0).reverse();
      });

    }


    //visuels
    const visuelsElems = container.querySelectorAll('.visuel--reveal');
    if (visuelsElems) {

      visuelsElems.forEach((elem) => {
        elem.visuelRevealTL = new TimelineMax({ paused: true});
        elem.visuelRevealTL.from(elem.querySelector('.placeholder'), 1, {x:"-100%", ease:Power3.easeOut}, 0);
        elem.visuelRevealTL.from(elem.querySelector('.holder'), 2, {scale:1.2, opacity:0, ease:Power3.easeOut}, 0.7);

        if (elem.previousElementSibling != null) {
          let muteBtn = elem.previousElementSibling;
          elem.visuelRevealTL.from(muteBtn, 1, {x:-40, opacity:0, ease:Power3.easeOut}, 0);
        }

        if (elem.nextElementSibling != null) {
          let legend = elem.nextElementSibling;
          elem.splitLegende = new SplitText(legend, {type:"chars"});
          elem.visuelRevealTL.staggerFrom(elem.splitLegende.chars, 1, {x:20, opacity:0, ease:Power3.easeOut}, 0.02, 0.2);
        }

      })

      inView("main .visuel--reveal").on('enter', el => {
        if(!el.done) el.visuelRevealTL.duration(animationDuration * 2).play();
      }).on('exit', el => {
        el.done = true;
        //el.visuelRevealTL.duration(0.2).reverse();
      });

    }

    //SlidersVisuels
    const slidersElems = container.querySelectorAll('.slider__visuels');
    if (slidersElems) {
      slidersElems.forEach((elem) => {
        elem.visuelRevealTL = new TimelineMax({ paused: true});
        elem.visuelRevealTL.from(elem.querySelector('.swiper-container'), 1, {x:"-100%", ease:Power3.easeOut}, 0);
        elem.visuelRevealTL.from(elem.querySelector('.swiper-wrapper'), 1, {opacity:0, ease:Power3.easeOut}, 0.7);
        if (elem.classList.contains('right')) {
          elem.visuelRevealTL.from(elem.querySelector('.swiper-buttons'), 0.5, {x:-40, opacity:0, ease:Power3.easeOut}, 0.7);
        } else {
          elem.visuelRevealTL.from(elem.querySelector('.swiper-buttons'), 0.5, {x:40, opacity:0, ease:Power3.easeOut}, 0.7);
        }


        if (elem.querySelector('.legend__visuel') != null) {
          let legend = elem.querySelector('.legend__visuel');
          elem.splitLegende = new SplitText(legend, {type:"chars"});
          elem.visuelRevealTL.staggerFrom(elem.splitLegende.chars, 1, {x:20, opacity:0, ease:Power3.easeOut}, 0.02, 0.2);
        }

      });

      inView("main .slider__visuels").on('enter', el => {
        if(!el.done) el.visuelRevealTL.duration(animationDuration * 2).play();
      }).on('exit', el => {
        el.done = true;
        //el.visuelRevealTL.duration(0.2).reverse();
      });

    }

    inView.offset({
        top: -120,
        left:-50,
        right:-50,
        bottom: 0,
    });

  }

  intro() {


    //removeLoading
    mainLoader.style.visibility = 'visible';
    TweenMax.set(mainLoader, {y:"0%"});

    let h1Split;
    if (!body.classList.contains('home')) { h1Split = new SplitText(document.querySelector('h1'), {type:"words, chars"}); }

    const intro = new TimelineMax({ paused:true, onComplete:()=>{
      mainLoader.style.visibility = 'hidden';
      if (!body.classList.contains('home')) { document.querySelector('h1').innerHTML = h1Split._originals; }
    }, onStart:()=>{
      body.classList.remove('is-loading');
    }})

    intro.to(mainLoader, 0.8, {y:"-100%", ease:Power3.easeOut}, 1);
    intro.to(mainLoader.querySelector('.brand'), 0.8, {y:window.innerHeight, ease:Power3.easeOut}, 1);
    if (!body.classList.contains('home')) { intro.staggerFrom(h1Split.chars, 0.8, {y:"80%", ease:Power3.easeOut}, 0.010, 0.8); }
    intro.from('.barba-container', 0.8, {y:200, ease:Power3.easeOut}, 1)
    intro.play();


  }

}

const app = new App();
