import * as utils from '../utils.js';

export function initServices() {

	//var pageHomeRender;
	var Services = Barba.BaseView.extend({

		namespace: 'page-services',

		onEnter: function() {

			console.log('Init Services');

			//Anchors
			const Anchors = document.querySelectorAll('.list__anchors a');
			Anchors.forEach((anchor) => {

				anchor.addEventListener('click',(e)=>{

					e.preventDefault();
					let anchorTarget = document.querySelector(e.currentTarget.getAttribute("href"));
					let targetStyle = anchorTarget.currentStyle || window.getComputedStyle(anchorTarget);
					let targetTop = anchorTarget.offsetTop - parseInt(targetStyle.marginTop);
					TweenMax.to(window,1.2, {scrollTo:{y:targetTop, autoKill:false}, ease:Power3.easeInOut});

				})

			});

      //Progress
      const visuelsProgress = this.container.querySelectorAll('.visuel--progress');
      if (visuelsProgress) {

        visuelsProgress.forEach(function(visuel) {

          visuel.draggTL = new TimelineMax({ paused: true});
          TweenMax.set(visuel.querySelector('.mask'), { x:"-100%" });
          TweenMax.set(visuel.querySelector('.mask img'), { x:"100%" });
          TweenMax.set(visuel.querySelector('.draggable'), { x:0 });
          visuel.draggTL.to(visuel.querySelector('.mask'), 1, { x:"0%", ease:Power0.easeNone  }, 0);
          visuel.draggTL.to(visuel.querySelector('.mask img'), 1, { x:"0%", ease:Power0.easeNone  }, 0);
          visuel.draggTL.to(visuel.querySelector('.draggable'), 1, { x:Math.round(visuel.offsetWidth), ease:Power0.easeNone  }, 0);
          visuel.draggTL.progress(0.5);

          visuel.addEventListener('mousemove', (e) => {
            let norm = utils.clamp(utils.normalize(e.pageX - e.currentTarget.getBoundingClientRect().left,  0 ,  Math.round(visuel.offsetWidth)), 0, 1);
            TweenMax.to(visuel.draggTL, 0.8, {progress:norm, ease:Power3.easeOut});
          })

          visuel.addEventListener('mouseleave', (e) => {
            TweenMax.to(visuel.draggTL, 0.8, {progress:0.5, ease:Power3.easeInOut});
          });

					visuel.addEventListener('touchmove', (e) => {
            let norm = utils.clamp(utils.normalize(e.changedTouches[0].pageX - e.currentTarget.getBoundingClientRect().left,  0 ,  Math.round(visuel.offsetWidth)), 0, 1);
            TweenMax.to(visuel.draggTL, 0.8, {progress:norm, ease:Power3.easeOut});
          })

        });
      }

			//Reveal
      const visuelsOver = this.container.querySelectorAll('.visuel--over');
      if (visuelsOver) {
        visuelsOver.forEach(function(visuel) {

          visuel.parentNode.querySelector('.icon--over').addEventListener('click', (e) => {
						e.preventDefault();
						visuel.classList.toggle('hover');
					})

        });
      }

		},

		onLeaveCompleted: function() {
			//TweenMax.ticker.removeEventListener("tick", pageHomeRender, this, true, 1);
		}

	})

	Services.init();

}
