import * as utils from '../utils.js';

class Header {

  constructor(el) {

    console.log('initHeader');

	  this.DOM = {};
	  this.DOM.el = el;
	  this.DOM.body = document.querySelector('body');
	  this.DOM.brand = this.DOM.el.querySelector('.brand');
	  this.DOM.toggle = this.DOM.el.querySelector('.toggle__menu');
	  this.DOM.menuContainer = document.querySelector('.menu__container');
	  this.DOM.visuelContainer = document.querySelector('.menu__visual');
    this.DOM.title = this.DOM.menuContainer.querySelector('.block__title');
    this.DOM.titleChars = new SplitText(this.DOM.title, {type:"chars"});
    this.randomVisuel;
    this.menuOpen = false;

	  this.init();

  }

  init() {

    //animation Open
    const menuOpenTL = new TimelineMax({ paused: true});
    //const LiensChars = new SplitText(this.DOM.menuContainer.querySelectorAll('.nav__link'), {type:"chars"});
    menuOpenTL.from(this.DOM.menuContainer.querySelector('.container'), 1, {y:-200, ease:Power3.easeOut}, 0.2);
    ///menuOpenTL.staggerFrom(this.DOM.menuContainer.querySelectorAll('.nav__item'), 1, {y:-100, opacity:0, ease:Power3.easeOut}, -0.1, 0.2);
    menuOpenTL.staggerFrom(this.DOM.menuContainer.querySelectorAll('.nav__item a'), 1, {y:"-100%", ease:Power3.easeOut}, -0.05, 0.2);
    //menuOpenTL.staggerFrom(this.DOM.menuContainer.querySelectorAll('.nav__item a div div div'), 1, {y:"-100%", ease:Power3.easeOut}, -0.01, 0.2);
    menuOpenTL.staggerFrom(this.DOM.title, 1, {y:-40, ease:Power3.easeOut}, 0.6);
    //menuOpenTL.staggerFrom(this.DOM.titleChars.chars, 1, {x:20, opacity:0, ease:Power3.easeOut}, 0.05, 0.5);
    menuOpenTL.staggerFrom(this.DOM.menuContainer.querySelectorAll('.contact > *'), 2, {x:-40, opacity:0, ease:Power3.easeOut}, 0.15, 0.2);

  	//toggle
  	this.DOM.toggle.addEventListener('click',(e) => {

  		e.preventDefault();
  		this.DOM.body.classList.toggle('showMenu');

  		if ( this.DOM.body.classList.contains('showMenu') ) {

        this.menuOpen = true;
        this.DOM.body.style.overflow = "hidden";
        this.DOM.menuContainer.style.visibility = "visible";

        menuOpenTL.totalDuration(2).restart();

        this.randomVisuel = this.DOM.visuelContainer.querySelector('.visuel-'+utils.randomInt(1, 5));
        TweenMax.set(this.randomVisuel, {visibility:"visible"});
        TweenMax.set(this.DOM.visuelContainer, {y:"100%"});
        TweenMax.to(this.DOM.visuelContainer, 0.8, {y:"0%", ease:Power3.easeOut});
        TweenMax.from(this.randomVisuel, 2, {y:"50%", x:"-100%", opacity:0, ease:Power3.easeOut});

        this.DOM.menuContainer.addEventListener('mousemove', utils.mouseMovefn );

  		} else {

        this.menuOpen = false;

        if (menuOpenTL.progress() < 0.54) {
          menuOpenTL.totalDuration(1).reverse();
        } else {
          menuOpenTL.totalDuration(1).reverse(-2);
        }

        this.DOM.menuContainer.removeEventListener('mousemove', utils.mouseMovefn );

        TweenMax.to(this.DOM.visuelContainer, 0.6, {y:"-100%", ease:Power3.easeInOut, overwrite:"all"});
        TweenMax.to(this.randomVisuel, 0.6, {y:"-200%", x:"50%", opacity:0, ease:Power3.easeInOut, onComplete:() => {

            TweenMax.set(this.randomVisuel, {visibility:"hidden", y:"-50%", x:"-50%", opacity:"1"});
            TweenMax.set(this.DOM.visuelContainer, {y:"100%"});

            this.DOM.body.style.overflow = "auto";
            this.DOM.menuContainer.style.visibility = "hidden";

        }});

  		}

  	});

    //Sticky
    let sy = 0;
    let baseSticky = this.DOM.el.offsetTop + this.DOM.el.offsetHeight  + 100;

    window.addEventListener('scroll', (e) => {
      sy = window.pageYOffset || document.documentElement.scrollTop;
    })

    var stickyRender = () => {
      if ( sy > baseSticky && !this.DOM.el.classList.contains('sticky') && !this.DOM.el.classList.contains('animating')) {
  			this.DOM.el.classList.add('sticky','animating');
        TweenMax.set(this.DOM.el, {y:-100});
  			TweenMax.to(this.DOM.el, 0.8, {y:0, overwrite:"all", ease:Power3.easeOut, onComplete:() => {
          this.DOM.el.classList.remove('animating');
        }});
  		}

      if ( sy < baseSticky && this.DOM.el.classList.contains('sticky') && !this.DOM.el.classList.contains('animating') ) {
        this.DOM.el.classList.add('animating');
  			TweenMax.to(this.DOM.el, 0.2, {y:-100, overwrite:"all", ease:Power3.easeIn, onComplete:() => {
          TweenMax.set(this.DOM.el, {y:0});
          this.DOM.el.classList.remove('sticky');
          utils.delay(150).then(() => {
            this.DOM.el.classList.remove('animating');
          });
        }});
      }
    }

    TweenMax.ticker.addEventListener("tick", stickyRender, this, true, 1);

    //onClick
    const links = this.DOM.menuContainer.querySelectorAll('.nav__link')
    links.forEach((elem) => {

      elem.addEventListener('click',(e)=> {
        if ( this.DOM.menuContainer.querySelector('.active') != null ) {
           this.DOM.menuContainer.querySelector('.active').classList.remove('active');
        }
        e.currentTarget.parentNode.classList.add('active');
      })

    });

    this.DOM.brand.addEventListener('click',(e)=> {

      if ( this.DOM.menuContainer.querySelector('.active') != null ) {
         this.DOM.menuContainer.querySelector('.active').classList.remove('active');
      }

    })



  }

}

export default Header;
