export function initJobs() {

	//var pageHomeRender;
	var Jobs = Barba.BaseView.extend({

		namespace: 'page-jobs',

		onEnter: function() {

			console.log('Init Jobs');

			//Anchors
			const Anchors = document.querySelectorAll('.list__anchors a');
			Anchors.forEach((anchor) => {

				anchor.addEventListener('click',(e)=>{

					e.preventDefault();
					let anchorTarget = document.querySelector(e.currentTarget.getAttribute("href"));
					let targetStyle = anchorTarget.currentStyle || window.getComputedStyle(anchorTarget);
					let targetTop = anchorTarget.offsetTop - parseInt(targetStyle.marginTop);
					TweenMax.to(window,1.2, {scrollTo:{y:targetTop, autoKill:false}, ease:Power3.easeInOut});

				})

			});

		},

		onLeaveCompleted: function() {
			//TweenMax.ticker.removeEventListener("tick", pageHomeRender, this, true, 1);
		}

	})

	Jobs.init();

}
